import React from 'react'

function HOC(props) {
  return (
    <div className='py-2 px-8 md:px-16'>
        {props.children}
    </div>
  )
}

export default HOC