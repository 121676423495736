import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';

function Login({ page }) {

  const [mesage, setmesage] = useState()
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");



  const formik = useFormik({
    initialValues: {
      username: '',
      address: '',
    },
    validationSchema: Yup.object({
      address: Yup.string().required('Wallet address is required'),
    }),
    onSubmit: (values) => {
      // Handle form submission here
      console.log(values);

      setmesage()

      fetch('https://referral-nzdy.onrender.com/getUser', {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          address: values.address,
        })
      })
        .then(res => res.json())
        .then(res => {
          if (res.error) {
            setmesage(res.error)
            return
          }
          console.log(res)
          setmesage('Login Successful!')
        })
        .catch(e => {
          console.log(e)
        })
    },
  });

  return (
    <div>
      <h1 className='text-center font-racing text-3xl font-black text-white text-stroke text-stroke-orange'>Ready to loot some sheep?</h1>

      <form onSubmit={(e) => {
        e.preventDefault()
        formik.handleSubmit()
      }}>
        {/* <div className='flex flex-col items-start mb-3'>
          <label className='text-xs mb-2' htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            name="username"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.username}
            className='border border-primary-orange text-white rounded w-full outline-none text-sm px-2 py-1'
            style={{ backgroundColor: "#9C570C" }}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className='text-xs'>{formik.errors.username}</div>
          ) : null}
        </div> */}

        <div className='flex flex-col items-start mb-3' >
          <label className='text-xs mb-2' htmlFor="address">Wallet Address:</label>
          <input
            type="text"
            id="address"
            name="address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            className='border border-primary-orange text-white rounded w-full outline-none text-sm px-2 py-1'
            style={{ backgroundColor: "#9C570C" }}
          />
          {formik.touched.address && formik.errors.address ? (
            <div className='text-xs'>{formik.errors.address}</div>
          ) : null}
        </div>

        <div>
          {mesage && <p>{mesage}</p>}
        </div>

        <div onClick={(e) => {
          e.preventDefault()
          formik.handleSubmit()
        }} className='flex items-center justify-center'>
          <button className="mx-auto border-2 p-2 px-6 rounded text-white shadow border-primary-orange mt-6 "
            style={{ backgroundColor: "#9C570C" }}
            type="submit">Login</button>
        </div>


        <div className=" mt-8 w-full flex justify-between items-center">
          <button onClick={() => page("")} className='hover:border-b hover:border-primary-orange'>View Challenge</button>
          <button onClick={() => page("login")} className='hover:border-b hover:border-primary-orange'>Go to Signup</button>
        </div>
      </form>

    </div>
  )
}

export default Login