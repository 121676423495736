import React, { useState } from 'react'
import Modal from '../../../UI_Elements/Modal/Modal'
import Challenge from './components/Challenge'
import Login from './components/Login'
import Signup from './components/Signup'



function ReferralModal(props) {
    const [page, setPage] = useState("")
    const pageHandler = (value)=>{
        setPage(value)
    }
  return (
    <div>
        <Modal show={props.show} onCancel={() =>{
            setPage("")
            props.onCancel()
        }} >
            <div className='px-4 py-2'>
                {page === "" && <Challenge page={pageHandler}/>}
                {page === "signup" && <Signup page={pageHandler}/>}
                {page === "login" && <Login page={pageHandler}/>}
            </div>
        </Modal>
    </div>
  )
}

export default ReferralModal