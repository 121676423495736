import React from 'react'
import { IoIosCopy } from "react-icons/io";
import { copyToClipBoard } from '../../../utils/copyToClipboard';



function Challenge({page}) {
    const twitterUrl = "This is where the twitter link would be"
    const onCopy = () =>{
        copyToClipBoard(twitterUrl)
    }
  return (
    <div>
        <h1 className='text-center text-xl font-racing font-semibold text-white text-stroke text-stroke-orange'>Complete the challenge by signing up to get free coins!</h1>
        <p className="text-center">Simply like, retweet and follow our post and twitter handle</p>
        <div className=' my-6 border-2 border-primary-orange rounded py-4 px-6 text-center relative' 
        style={{backgroundColor:"#9C570C"}}>
            <IoIosCopy onClick={onCopy} className='absolute top-2 right-3 text-white text-xs z-10 cursor-pointer'/>
            <p className='text-white text-xs my-0'>{twitterUrl}</p>
        </div>
        <div className="flex justify-center items-center gap-6">
            <button onClick={() => page("signup")} className="custom-btn " id="button-3">
                <div id="circle"></div>
                <h2 className="custom-a poppins__bold ">SignUp</h2>
            </button>
            <button onClick={() => page("login")} className="custom-btn " id="button-3">
                <div id="circle"></div>
                <h2 className="custom-a poppins__bold " >Login</h2>
            </button>
        </div>
    </div>
  )
}

export default Challenge