import React from 'react'

import * as Icons from 'react-icons/bs'
import { images } from '../../assets'

function Footer() {
  return (
    <div className='bg-primary-thick-black py-8 px-8 md:px-16 flex flex-col md:flex-row justify-center items-center'>
        <img src={images.logo[0]} alt='Time Flox logo' className='h-28 object-contain mr-4'/>

        <div className='mt-4 md:mt-0 flex flex-wrap justify-center items-center'>
            <a href='https://t.me/sheeploot' target='_blank' rel='noreferrer' className='flex flex-col mx-4 justify-center items-center '>
                <Icons.BsTelegram className='text-white text-2xl hover:text-primary-yellow'/>
                <p className='text-white  poppins__light mt-2'>Telegram</p>
            </a>

            <a href='https://x.com/sheeploot' target='_blank' rel='noreferrer' className='flex flex-col mx-4 justify-center items-center '>
                <Icons.BsTwitter className='text-white text-2xl hover:text-primary-yellow'/>
                <p className='text-white  poppins__light mt-2'>Twitter</p>
            </a>

            {/* <a href='/files/the-lamb-club.zip' target='_blank' rel='noreferrer' className='flex flex-col mx-4 justify-center items-center '>
                <IoLogoGameControllerB className='text-white text-2xl hover:text-primary-yellow'/>
                <p className='text-white  poppins__light mt-2'>Game</p>
            </a> */}

            {/* <div className='flex flex-col mx-4 justify-center items-center '>
                <Icons.BsDiscord className='text-white text-2xl hover:text-primary-glow'/>
                <p className='text-white  poppins__light mt-2'>Discord</p>
            </div>

            <div className='flex flex-col mx-4 justify-center items-center '>
                <Icons.BsFacebook className='text-white text-2xl hover:text-primary-glow'/>
                <p className='text-white  poppins__light mt-2'>Facebook</p>
            </div> */}
        </div>
    </div>
  )
}

export default Footer