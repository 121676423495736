import ReactGA from 'react-ga';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Initialize Google Analytics with your tracking ID
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

const GoogleAnalytics = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Function to track page view on route change
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [navigate]);

  return null;
};

export default GoogleAnalytics;
