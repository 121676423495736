import React, {useState, useEffect} from 'react'
// import {Link} from 'react-router-dom' 
// import { NavLink } from 'react-router-dom'

import {navItems} from "./NavItems.js";
import {images} from '../../assets/index'

import * as Icons from "react-icons/fa";
import {HiBars3BottomLeft} from 'react-icons/hi2';

import "./Navbar.css";
import ReferralModal from '../../pages/components/referral/Index.js';


function Landing() {
    const [mobile,
        setMobile] = useState(false);
    const [sidebar,
        setSidebar] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 800) {
            setMobile(true);
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 800) {
                setMobile(true);
            } else {
                setMobile(false);
                setSidebar(false);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const [showModal, setShowModal] = useState(false)

    const toggleModalHandler = () => {
        // console.log("toggle show")
        setShowModal(!showModal)
    }


    return (
        <>
        <ReferralModal show={showModal} onCancel={toggleModalHandler}/>
        <div className='flex justify-between items-center '>
            <img
                src={images.logo[0]}
                alt={images.logo[1]}
                title={images.logo[1]}
                caption={images.logo[1]}
                className='object-contain w-12'/> 

            {!mobile && (
                    <ul className='flex my-auto'>
                        {navItems.map((item) => {
                            return (
                                <li key={item.id} className={item.nName}>
                                    <a href={item.path} className={`${item.nName} text-sm p-2 poppins__thin__light`}>{item.title}</a>
                                </li>
                            );
                        })
                    }   
                        
                    </ul>
                )}
                


        {mobile && (
          <div className="sidebar-toggle">
            {sidebar ? (
              <Icons.FaTimes
                className="sidebar-toggle-logo"
                onClick={() => setSidebar(!sidebar)}
              />
            ) : (
              <HiBars3BottomLeft
                className="sidebar-toggle-logo "
                onClick={() => setSidebar(!sidebar)}
              />
            )}
          </div>
        )}
        </div>
        
        <div className={sidebar ? "sidebar shadow-md z-50 active" : "sidebar "} >
        <div className='span__pseudo' onClick={() => setSidebar(!sidebar)}></div>
        <div className='w-full flex items-center p-2  bg-white'>
        <img
                src={images.logo[0]}
                alt={images.logo[1]}
                title={images.logo[1]}
                caption={images.logo[1]}
                className='object-contain w-12  '/> 
            <p className='text-2xl sidebar_p ml-1 '>Sheep loot</p>
        </div>
        
        <ul className="sidebar-items">
        {navItems.map((item) => {
                        if(item.type){
                            console.log("heeelo")
                            return (
                                <li key={item.id} className={item.sName}>
                                    <button
                                    className={`text-sm text-black poppins__normal`}
                                    onClick={() => {
                                        console.log("hi")
                                        toggleModalHandler()
                                        setSidebar(!sidebar)
                                        }}>{item.title}</button>
                                </li>
                            );
                        }
                        return (
                            <li key={item.id} className={item.sName}>
                                <a href={item.path} 
                                className={`text-sm text-black poppins__normal`}
                                onClick={() => {
                                    if(item.type){
                                        console.log("hi")
                                        toggleModalHandler()
                                    }
                                    setSidebar(!sidebar)
                                }}>{item.title}</a>
                            </li>
                        );
                    })
        }
            
        </ul>
        
      </div>
        </>
    )
}

export default Landing
