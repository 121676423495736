import { useState, useCallback } from 'react'

export const useData = () => {
    const {address, setAddress} = useState({
        username:null,
        wallet: null
    })

    const updateDetails = useCallback((username,wallet) =>{
        setAddress({
            username:username,
            wallet: wallet
        })

        localStorage.setItem(
            'sheepLootUserData',
            JSON.stringify({
                username:username,
                wallet: wallet
            })
        );
        
    }, [setAddress])

    const getDetails = useCallback(() =>{
        if (localStorage.getItem('sheepLootUserData')) {
            let userData = JSON.parse(localStorage.getItem('sheepLootUserData'));
            return userData
        }
        
    }, [])
    const removeDetails = useCallback(() =>{
        if (localStorage.getItem('sheepLootUserData')) {
            localStorage.removeItem('sheepLootUserData')
        }
        
    }, [])
    return {address, updateDetails, getDetails, removeDetails}
}
